<template>
	<div class="visa-more-product-total">
		<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qqqz_tt%402x.png" class="title-icon-1" />
		<div class="content">
			<div class="product-bar">
				<HomeTitleBar v-if="areaid != 0" :type="Number('10')" :title="areaname + '签证'" desc="" :tab_array="[]"
					:hideMore="true"></HomeTitleBar>
				<HomeTitleBar v-else :type="Number('11')" title="热门签证" desc="热  门  推   荐" :tab_array="[]">
				</HomeTitleBar>
				<div class="product-more-bar">
					<HomeVisaItem v-for="(item, index) in product_visa" :index="index" :key="item.visaId"
						:img_src="item.visaImg" :title="item.visaName" :place="item.Country" :send_place="item.VisaCity"
						:price="item.visaPrice" :desc="item.JXPrice" @clickProductItem="clickProductItem">
					</HomeVisaItem>
				</div>
			</div>
		</div>
		<el-pagination v-if="areaid == 0" class="page" @current-change="currentChange" background :current-page="page"
			:page-size="pageSize" layout="prev, pager, next" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		getVisaMain,
		getVisaAreaProduct
	} from "@/api/index";
	import HomeVisaItem from "../components/Home_Visa_Item.vue";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		components: {
			HomeVisaItem,
			HomeTitleBar,
		},
		data() {
			return {
				page: 1,
				pageSize: 16,
				total: 0,
				areaid: 0,
				areaname: "",
				product_visa: [],
			};
		},
		created() {
			if (this.$route.query.areaid != undefined) {
				this.areaid = Number(this.$route.query.areaid);
				this.areaname = this.$route.query.areaname;
				this.getVisaAreaProduct();
			} else {
				this.getVisaMain();
			}
		},
		methods: {
			clickProductItem(e) {
				let index = e.index;
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/VisaDetail?id=" + this.product_visa[index].visaId + "&tab_name=7+org=" + org,
					"_blank"
				);
			},

			//当前页码改变
			currentChange(e) {
				this.page = e;
				this.getVisaMain();
			},

			getVisaAreaProduct() {
				getVisaAreaProduct(this.areaid).then((res) => {
					this.product_visa = res.visalist;
				});
			},

			getVisaMain() {
				getVisaMain(this.page, this.pageSize).then((res) => {
					this.product_visa = res.visalist;
					this.total = res.pageinfo.totalcount
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.visa-more-product-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		padding-bottom: 100px;

		.page {
			margin-top: 30px;
			margin-bottom: 50px;
		}

		.title-icon-1 {
			width: 100%;
			margin-bottom: 20px;
		}

		.content {
			width: 1200px;

			.product-bar {
				display: flex;
				z-index: 1;
				width: 1200px;
				flex-direction: column;

				.product-more-bar {
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					margin-right: 22px;
				}
			}
		}
	}
</style>
